import React from 'react'
import styled from 'styled-components'

import Box from './box'

    const BoxWrapper = styled.div`

    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    
    `


    const LinkBoxes = () => {

    return(

        <BoxWrapper>

        <Box key='1'
            title='rowriter'

            links={[['npm', 'https://www.npmjs.com/package/rowriter'],['github', 'https://github.com/benjfriedrich/rowriter']]}

        />

        <Box key='2'
        title='rowriter-react'

        links={[['npm', 'https://www.npmjs.com/package/rowriter-react'],['github', 'https://github.com/benjfriedrich/rowriter-react']]}

    />

        </BoxWrapper>

    )
 
    }

    export default LinkBoxes