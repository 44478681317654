import React from 'react'
import styled from 'styled-components'

    const Wrapper = styled.div`

    text-align: center;
   
    width: 600px;
    max-width: 90%;

    margin: 0 auto;

    `

    const Heading = styled.h2`

    font-size: 33pt;

    margin-top: 40px;

    @media only screen and (max-width: 600px ) {

        font-size: 25pt;
    }
    
    `

    const Para = styled.p`

    font-size: 16pt;
    line-height: 22pt;

    @media only screen and (max-width: 600px ) {

        text-align: left;
    }
    

    
    `

    const Text = () => {

    return(

    <Wrapper>

    <Heading>When is this useful?</Heading>

    <Para>
    RoWriter is a niche tool. Most of the time CSS flex or grid can take care of your row making needs right out of the box. In fact, the CSS approach is much simpler and you should probably just use it, and just deal with the occasional oddity. But if you are a person whose rows need to be just right, RoWriter can help get you out of a tight spot.  RoWriter shines when you want to center a multi-row list of items, espeacially when you don’t know the exact length of the list. 
    </Para>

    <Para>
    It's a javascript function, so it works on the browser or in a Node environment. Practically speaking, it's probably most useful in front-ends built with a JS framework.  
    </Para>


    <Heading>What exactly does it do?</Heading>

    <Para>
    RoWriter takes an array of items and a maximum row width from you and returns an array of arrays that each contain a properly lengthed row of your items. From there you can render those arrays into html using whatever tools you like.
    </Para>

    <Heading>Does it have limitations?</Heading>

    <Para>
    Oh boy, does it ever! The key limitation is that out of the box it cannot adjust the number of items per row based on things like browser width. That sort of thing depends on your implementation.
    </Para>
    
    <Para>
    It’s just a JS function.
    </Para>

    <Para>
    But that’s also the beauty part. You can build it into your own client-side code, and trigger rerenders or rerenders however you want. 
    </Para>

    <Heading>What about React?</Heading>

    <Para>
    So weird that you happened to ask about React. I just so happen to have a react component on NPM that uses RoWriter. <a href='https://www.npmjs.com/package/rowriter-react'>You can check it out here.</a>
    </Para>

    <Heading>That's all. Make cool stuff!</Heading>
    
    </Wrapper>

    )
 
    }

    export default Text