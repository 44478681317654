/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, {keyframes} from 'styled-components'
import "./layout.css"

// import useScrollHeight from '../hooks/useScrollHeight'


import useDimensions from 'react-use-dimensions'

import water from '../images/longRiver.svg'


const flow =keyframes`

from {transform: translateY(-100%)}
    to {transform: translateY(-200%)}

 `

const PageWrapper = styled.div`

/* background-color: #00a2ff; */

/* background-image: url(${water}); */

background-size:contain;
    background-color: #00a2ff;
    background-repeat: repeat-y;
height: 100vh;

display: inline-block;
width: 100%;

/* height: 100%; */

overflow: hidden;



`

const BigWrapper = styled.div`

`

const River = styled.div`

  /* width: 96%; */


  width: 100%;

    position: relative;
    animation: ${flow}  100s infinite steps(4000, end);
    display: inline-block;

   

    /* background-image: url(${water}); */

    /* animation: ${flow} 2000s linear infinite; */


    &:after {
      content: "";
  height: 500%;
  width: 100%;
  left: 0px;
  position: absolute;
  background-image: url(${water});
  background-repeat: repeat-y;
  background-size: contain;


    }
`
const RiverImg = styled.img`

  width: 100%;
  margin: -5px 0;
  padding: 0;

  z-index: -100;

`



const Content = styled.div`

  width: 96%;

  position: absolute;
  top: 2vw;

`

const Layout = ({ children }) => {

  const [ref, size] = useDimensions();





  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (

    <BigWrapper>
    <PageWrapper
    
    style={{

      'height':`${size.height + size.top + 10}px`
    }}
    >

    <River>
 <RiverImg src={water}/>
    </River>
    </PageWrapper>

    <Content ref={ref} id='content'>

    {children}
     </Content>

    </BigWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
