import React from 'react'
import styled from 'styled-components'

const TheBox = styled.div`

    border: 5px white solid;
    border-radius: 18px;
    width: 200px;
    font-size: 15pt;
    text-transform: uppercase;
    font-weight: bold;

    margin: 10px;

`   

const Head = styled.div`

    color: #00a2ff;
    background-color: white;
    border-radius: 10px 10px 0 0;
    padding: 0px 0 0 10px;

`

const Body = styled.ul`

    list-style-type: none;

    padding: 10px;

    margin: 0 0 0 0;
`

const ListItem =  styled.li`

    margin: 0 0 0 0;

`

const ALink = styled.a`

text-decoration: none;
color:white;

&:visited{

    color: white;
}


`

    const LinkBox = (props) => {

        const title = props.title;

        const links = props.links;



    return(

    <TheBox>
    <Head>{title}</Head>

    <Body>

    { links.map(i => <ListItem key={i[0]}><ALink href={i[1]}>{i[0]}</ALink></ListItem>)}

    </Body>

    </TheBox>

    )
 
    }

    export default LinkBox