import React, {useState} from 'react'
import styled from 'styled-components'
import RoWriter from 'rowriter-react'

import RoWrite from 'rowriter'

import Counter from './counter'

import Kayak from './kayak/kayak'

import StaticKayak from './staticKayak'

const BoldPart = styled.h2`

font-size: 27pt;
font-weight: bold;
text-align: center;
margin-bottom: 27px;


`
const CounterHolder = styled.div`

    display: flex;
    justify-content: center;
    align-content: center;

    max-width: 100%;

`

const Rows = styled(RoWriter)`


    margin: 40px 0 100px;

    @media (max-width: 600px) {

        margin: 40px 0 50px;

    }


`


    const TryIt = () => {

        const [count, setCount] = useState(5)
        const addCount = () => setCount(count + 1);
        const subtractCount = () => count > 1 ? setCount(count - 1) : null;

        const [maxWidth, setMaxWidth] = useState(4)
        const addMaxWidth = () => setMaxWidth(maxWidth + 1);
        const subtractMaxWidth = () => maxWidth > 1 ? setMaxWidth(maxWidth - 1) : null;

        const [renderedWidth, setRenderedWidth] = useState(4)

        const actualWidth = RoWrite([...Array(count)], maxWidth)[0].length

        console.log(actualWidth)
        const items = [...Array(count)].map(() => <Kayak width={actualWidth} rowSpeed={Math.random()} />);

        

    return(
    <>

    <BoldPart>Try it out:</BoldPart>
    <CounterHolder>
    <Counter title='Max Width' count={maxWidth} add={addMaxWidth} sub={subtractMaxWidth}/>
    <Counter title='Items' count={count} add={addCount} sub={subtractCount}/>

    </CounterHolder>
    
        <Rows list={items} maxWidth={maxWidth} setWidth={setRenderedWidth}/>

    </>)
 
    }

    export default TryIt