import React from 'react'
import styled from 'styled-components'

import Arrow from '../../images/leftButton.svg'
import RArrow from '../../images/rightButton.svg'


    const ButtonWrapper = styled.div`


    text-align: center;

    display: inline-block;

    margin: 0 10px;
    
    `

    const Title = styled.div`

    margin: 0 auto 2px;
    font-size: 16pt;
    font-weight: bold;
    
    `

    const ButtonBody = styled.div`

    margin: 0 auto;

    border: 3px white solid;

    border-radius: 15px;
    width: 120px;
   
    display: flex;
    align-content: center;
    justify-content: space-between;

    padding: 5px 10px;

    `

    const ArrowHolder = styled.div`

    width: 12px;
    background-color:none;
    display: flex;
    align-content: center;
    justify-content: center;
    cursor: pointer;

    `

    const Count = styled.div`

    font-size: 35pt;
    font-weight: 700;

    margin: 0 4px;
    line-height: 35pt;

    letter-spacing: 0px;

    `


    const Counter = (props) => {

        const add = props.add
        const sub = props.sub
        const title = props.title
        const count = props.count


    return(
    <ButtonWrapper>

    <Title>{title}</Title>

    <ButtonBody>
    {count > 1 ?<ArrowHolder onClick={sub}><img style={{'margin':'0px', width:'100%'}} src={Arrow}/></ArrowHolder>:<ArrowHolder onClick={sub}><img style={{width:'100%', opacity:'.0'}} src={Arrow}/></ArrowHolder>}
   
    <Count>{count}</Count>

    <ArrowHolder onClick={add}><img style={{'margin':'0px', width:'100%','transform':'rotate(180)'}} src={RArrow}/></ArrowHolder>
    
    </ButtonBody>

        
    </ButtonWrapper>
    )
 
    }

    export default Counter