import React from 'react'
import styled from 'styled-components'

import oar from '../images/oar.svg'


const Badge = styled.div`

text-align: center;
    margin: 6vh auto 0;
    
    @media screen and (min-width: 600px) {
	
    margin: 1vh auto 0;		

        }

`
const Title = styled.h1`
        
        text-align: center;
		font-size: 35pt; 
		margin: 0px 0;

		@media screen and (min-width: 600px) {
		
            font-size: 50pt;
            
            margin: -5px 0 5px;
		
		}

`

const OarImg = styled.img`

    max-width: 180px;
	margin: 0 auto;

	@media screen and (min-width: 600px) {
	
		max-width: 280px;		
	
			}


`

const Intro = styled.h2`

        text-align: center;
		font-size: 18pt; 
		margin: 40px 0;

		@media screen and (min-width: 600px) {
		
            font-size: 28pt;
            

		
		}


`

    const MainLogo = () => {

    return(

    <Badge>
    
    <OarImg src={oar}/>

    <Title>RoWriter()</Title>


    <OarImg style={{'transform':'rotate(180deg)'}} src={oar}/>

    <Intro>Make nice, even rows of things.</Intro>
    
    </Badge>

    )
 
    }

    export default MainLogo