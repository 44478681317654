import React from 'react'
import styled from 'styled-components'

import bfSig from '../images/bfSig.svg'

const Sig = styled.img`

    height: 45px;
	margin: 15px 0 0 15px;

	@media screen and (min-width: 600px) {
	
				height: 55px;
				margin: 20px 0 0 20px;
			}

`

    const Signiture = () => {

    return(

    <a href='https://benjaminfriedrich.com'>

        <Sig src={bfSig}/>

    </a>

    )
 
    }

    export default Signiture