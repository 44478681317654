import React from 'react'
import styled from 'styled-components'

import BadFish from '../../images/badFish.svg'
import GoodFish from '../../images/goodFish.svg'

    const Wrapper = styled.div`
        width: 100%;
        text-align: center;
        
    
    `

    const Para = styled.p`

    max-width: 400px;
    margin: 50px auto;

    font-size: 20pt;
    text-align: center;
    line-height: 27pt;
    padding: 0 15px;
    
    `

    const BoldPart = styled.figcaption`

    font-size: 27pt;
    font-weight: bold;
    text-align: center;
    margin-bottom: 35px;

    
    `

    const Figure = styled.figure`

    text-align: center;
    
    `
    const Image = styled.img`

    border: white 6px solid;
    border-radius: 20px;

    margin: 0 auto;
    height: 175px;

    
    padding: 20px;
    
    `

    const TheProblem = () => {

    return(

   <>
        <Para>Imagine two rows. The first row has 4 items. The second has 1 item. </Para>

        <Figure>
        <BoldPart>This is hell.</BoldPart>
        <Image src={BadFish} alt='Akwardly arranged fish'/>
        </Figure>

        <Para>Now, imagine two rows. The first row has 3 items. The second has 2 items.</Para>

        <Figure>
        <BoldPart>This is much better.</BoldPart>
        <Image src={GoodFish} alt='Nicely arranged fish'/>
        </Figure>
        <Para>If you're going to have mulitiple rows, they may as well be balanced so the rows are as short as possible.</Para>

        <Para>Give RoWriter a list of items and set the maximum items-per-row, and it will make it so you have as few rows as posible, with the fewest items per row.</Para>

        <Para>RoWriter is a javascript function that makes your rows more pleasing.</Para>
   </>

    )
 
    }

    export default TheProblem