import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import Sig from '../components/sig'
import LogoArea from '../components/mainLogo'

import LinkBoxes from '../components/linkBoxes/linkBoxes'

import TheProblem from '../components/theProblem/theProblem'

import TryIt from '../components/tryIt/tryIt'

import Text from '../components/Text'

import Footer from '../components/Footer'

import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

  <Sig/>
  <LogoArea/>
  <LinkBoxes/>
  <TheProblem/>
  <TryIt/>
  <Text/>
  <Footer/>


  </Layout>
)

export default IndexPage
