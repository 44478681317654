import React from 'react'
import styled, {keyframes} from 'styled-components'

import boatHull from '../../../images/boatHull.svg'
import boatWake from '../../../images/boatWake2.svg'

import boatArms from '../../../images/boatArms.svg'

import boatHat from '../../../images/boatHat.svg'


const sway =keyframes`

  0% {
    transform: translateX(0)
  }



  100% {
    transform: translateX(5px);
  }

 `

const oarFlip =keyframes`

0% {
    transform: scaleX(1) translateX(0);
}


50% {
    transform: scaleX(-1) translateX(-10%);
}

`

const oarFlip2 =keyframes`

0% {
    transform: scaleX(1) translateX(0);
}


25% {
    transform: scaleX(-1) translateX(-10%);
}

50% {
    transform: scaleX(1) translateX(0%);
}

75% {
    transform: scaleX(-1) translateX(-10%);
}

100% {
    transform: scaleX(1) translateX(0%);
}


`

const hatRotate =keyframes`

0% {
    transform:translateX(0%) rotate(-4deg);
    
}


25% {
    transform: translateX(10%) rotate(4deg);
}
50% {
    transform:translateX(0%) rotate(-4deg);
    
}

75% {
    transform: translateX(10%) rotate(4deg);
}

100% {
    transform:translateX(0%) rotate(-4deg);
}
`

const boatRotate3 =keyframes`

0% {
    transform:translateX(0) rotate(0) translateY(-1.5%);
}

10% {
    transform:translateX(0) rotate(0) translateY(0);
}

22% {
    transform:translateX(0) rotate(0) translateY(0);
}

25% {
    transform: translateX(0) rotate(0) translateY(-1.5%);
}

35% {
    transform:translateX(0) rotate(0) translateY(0);
}

47% {
    transform:translateX(0) rotate(0) translateY(0);
}

50% {
    transform: translateX(0) rotate(0) translateY(-2%);
}

60% {
    transform:translateX(0) rotate(0) translateY(0);
}

72% {
    transform:translateX(0) rotate(0) translateY(0);
}

75% {
    transform: translateX(0) rotate(0) translateY(-1.5%);
}

85% {
    transform:translateX(0) rotate(0) translateY(0);
}

97% {
    transform:translateX(0) rotate(0) translateY(0);
}

100% {
    transform: translateX(0) rotate(0) translateY(-1.5%);
}

`


















const boatRotate2 =keyframes`

0% {
    transform:translateX(1%) rotate(.2deg) translateY(0);
}

5% {
    transform:translateX(1%) rotate(.2deg) translateY(0);
}

20% {
    transform: translateX(1%) rotate(.2deg) translateY(0);
}



30% {
    transform: translateX(-1%) rotate(-.2deg) translateY(-1.5%);
}

/* 40% {
    transform:translateY(0px);
} */

50% {
    transform:translateX(-1%) rotate(-.2deg) translateY(0);
}

70% {
    transform: translateX(-1%) rotate(-.2deg) translateY(0);
}

80% {
    transform: translateX(1%) rotate(.2deg) translateY(-1.5%);
}

/* 93% {
    transform:translateY(0px);
} */

95% {
    transform:translateX(1%) rotate(.2deg) translateY(0);
}

100% {
    transform:translateX(1%) rotate(.2deg) translateY(0);
}


`

const boatRotate =keyframes`

0% {
    transform:translateX(1%) rotate(.2deg);
}

32% {
    transform: translateX(1%) rotate(.2deg);
}



60% {
    transform: translateX(-1%) rotate(-.2deg);
}


100% {
    transform:translateX(-1%) rotate(-.2deg);
}
`


 const Wrapper = styled.div`
 position:relative;
 animation: ${sway} linear both alternate infinite;
 `

 const BoatImg = styled.img`
 
    position: absolute;
    margin: 0 auto;
    top: 0;
    left:0;
    right: 0;


 `

 const Wake = styled(BoatImg)`

left: 10%;
/* animation: ${boatRotate} 1.5s linear alternate infinite; */
margin-top: 1%;
width:88%
 
 `

 const Hull = styled(BoatImg)`

 width: 40%;
 left: 10%;
 margin-top: 38%;

 animation: ${boatRotate3} steps(60,start) infinite;
 /* animation-timing-function:  ; */
 
 `


const Arms = styled(BoatImg)`

width: 100%;

margin-top: 82%;

animation: ${oarFlip2} step-start infinite;

`

const Hat = styled(BoatImg)`

width: 16%;
left: 8%;

margin-top: 85%;

animation: ${hatRotate} step-start infinite;

`




    const kayak = (props) => {

        const width = props.width

        const proportion = 100 / width

        const kayakWidth = proportion

        const rand = props.rowSpeed

    return(
        <div style={{'position':'static','height':`${kayakWidth * 1.5}vw`, 'maxHeight':'260px'}}>

    <Wrapper style={{'width':`calc((100vw - (22vw + (${10 / width}vw * (${width} - 1)))) / ${width})`, 'maxWidth':'150px', 'padding':'0px 0px','margin':`0px ${10 / width}vw`, 'animationDelay':`${rand}s`,'animationDuration':`${rand  + .8}s`}}>
    
    <Wake src={boatWake}/>
    <Hull src={boatHull} style={{'animationDelay':`${rand}s`,'animationDuration':`${(rand  + .8) * 2}s`}}/>
    <Arms src={boatArms} style={{'animationDelay':`${rand}s`,'animationDuration':`${(rand  + .8) * 2}s`}}/>
    <Hat src={boatHat} style={{'animationDelay':`${rand}s`,'animationDuration':`${(rand  + .8) * 2}s`}}/>

    
    </Wrapper>
    </div>

    )
 
    }

    export default kayak